import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import BatchesTable from './BatchesTable';
import NewBatchForm from './NewBatchForm';
import { BatchResponse, get } from '../api';

export const Allocation = () => {
  const [batches, setBatches] = useState<BatchResponse[]>([]);

  const fetchData = async () => {
    const fetchedBatches = await get<BatchResponse[]>('/api/v1alpha1/batches/');
    setBatches(fetchedBatches);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNewBatch = () => {
    fetchData();
  };

  return (
    <Stack sx={{ alignItems: 'flex-start', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h2>Allocation Batches</h2>
          <BatchesTable batches={batches} />
        </Grid>
        <Grid item xs={12}>
          <h2>New Batch</h2>
          <NewBatchForm onBatchAdded={handleNewBatch} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Allocation;
