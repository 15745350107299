import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { InputLabel, Input, TextField, Grid } from '@mui/material';
import { apiFetch } from '../api';
import Cookies from 'js-cookie';
import AllocatorUIModal, { modalStore, ModalTypes } from '../AllocatorUIModal';
import { LoadingButton } from '@mui/lab';

const headers: HeadersInit = {
  'Content-Type': 'application/json',
  'X-CSRFToken': Cookies.get('csrftoken') || ''
};

export const Import = () => {
  const [isImportDisabled, setIsImportDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [choicesJson, setChoicesJson] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const { setModal } = modalStore();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileRead = (file: File, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const fileContent = event.target?.result as string;
      setter(fileContent);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    if (collectionName.length > 0 && choicesJson.length > 0) {
      setIsImportDisabled(false);
    } else {
      setIsImportDisabled(true);
    }
  }, [choicesJson, collectionName]);

  const handleImport = () => {
    setIsLoading(true);
    setIsImportDisabled(true);

    const requestData = {
      choices_json: choicesJson,
      name: collectionName
    };

    apiFetch('/api/v1alpha1/studentchoicescollection/', {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: headers
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data[0]);
          });
        }
        return res.json();
      })
      .then((data) => {
        setChoicesJson('');
        setCollectionName('');
        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }
        setModal('Import successful', ModalTypes.MESSAGE);
      })
      .catch((error) => {
        setModal(error.message, ModalTypes.ALERT);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChoicesFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileRead(file, setChoicesJson);
    }
  };

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={8}>
          <InputLabel htmlFor="choices-json-input">Choices JSON</InputLabel>
          <Input
            id="choices-json-input"
            type="file"
            inputRef={inputFileRef}
            onChange={handleChoicesFileChange}
            inputProps={{
              accept: 'application/json'
            }}
            sx={{ p: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <InputLabel htmlFor="collection-name-input">Collection Name</InputLabel>
          <TextField
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
            placeholder="Enter text..."
            id="collection-name-input"
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            disabled={isImportDisabled}
            loading={isLoading}
            variant="contained"
            onClick={handleImport}
          >
            Import
          </LoadingButton>
        </Grid>
      </Grid>
      <AllocatorUIModal />
    </>
  );
};

export default Import;
