import React, { useEffect, useState } from 'react';
import { TextField, Grid } from '@mui/material';
import { Timetable, StudentChoicesCollection, get } from '../api';
import { modalStore, ModalTypes } from '../AllocatorUIModal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createMuiRegister, useGenericSubmit, FormSelect } from '../forms';
import { LoadingButton } from '@mui/lab';

type Fields = {
  name: string;
  description: string;
  studentChoicesCollectionId: string;
  timetableId: string;
};

const FIELD_NAMES = ['name', 'description', 'studentChoicesCollectionId', 'timetableId'];

type NewBatchFormProps = {
  onBatchAdded: () => void;
};

const NewBatchForm: React.FC<NewBatchFormProps> = ({ onBatchAdded }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError
  } = useForm<Fields>();
  const muiRegister = createMuiRegister<Fields>(register, errors);

  const { setModal } = modalStore();

  const onSuccess = () => {
    reset();
    onBatchAdded();
  };

  const onError = (message: string) => {
    setModal(message, ModalTypes.ALERT);
  };

  const [isSubmitting, onGenericSubmit] = useGenericSubmit(
    '/api/v1alpha1/batches/',
    FIELD_NAMES,
    setError,
    onSuccess,
    onError
  );

  const onSubmit: SubmitHandler<Fields> = async (data) => {
    onGenericSubmit({
      name: data.name,
      description: data.description,
      student_choices_collection: data.studentChoicesCollectionId,
      timetable: data.timetableId
    });
  };

  const [studentChoicesCollections, setStudentChoicesCollections] = useState<
    StudentChoicesCollection[]
  >([]);
  const [timetables, setTimetables] = useState<Timetable[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const StudentChoicesCollection = await get<StudentChoicesCollection[]>(
          '/api/v1alpha1/studentchoicescollection/'
        );
        setStudentChoicesCollections(StudentChoicesCollection);

        if (!StudentChoicesCollection.length) {
          setModal(
            'No student choices have been added. Add at least one batch.',
            ModalTypes.ALERT
          );
        }
      } catch (error) {
        setModal(
          'An error occurred looking up student choices: ' + (error as Error).toString(),
          ModalTypes.ALERT
        );
      }

      try {
        const timetableData = await get<Timetable[]>('/api/v1alpha1/timetables/');
        setTimetables(timetableData);

        if (!timetableData.length) {
          setModal('No timetables have been added. Add at least one timetable.', ModalTypes.ALERT);
        }
      } catch (error) {
        setModal(
          'An error occurred looking up timetables: ' + (error as Error).toString(),
          ModalTypes.ALERT
        );
      }
    };
    fetchData();
  }, [setModal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField {...muiRegister('name')} label="Name" required />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...muiRegister('description')}
            label="Description"
            multiline
            rows={3}
            required
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <FormSelect
            control={control}
            name="studentChoicesCollectionId"
            label="Student Choice Collection"
            fieldError={errors.studentChoicesCollectionId}
            choices={studentChoicesCollections.map((choice: StudentChoicesCollection) => ({
              value: choice.id,
              label: choice.name
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormSelect
            control={control}
            name="timetableId"
            label="Timetable"
            fieldError={errors.timetableId}
            choices={timetables.map((timetable: Timetable) => ({
              value: timetable.id,
              label: timetable.name
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Create Batch*
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <sub style={{ color: 'red' }}>
            * Creating a batch costs ~£50. Please use this functionality sparingly.
          </sub>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewBatchForm;
