import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

const StyledPaper = styled(Paper)(() => ({
  width: '100%',
  background: '#F4F5F7'
}));

const EmptyPractical: React.FC = () => (
  <StyledPaper elevation={0}>
    <Typography variant="body1" sx={{ p: 2 }}>
      There are no practicals for this subject
    </Typography>
  </StyledPaper>
);

export default EmptyPractical;
