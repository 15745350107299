import { Typography } from '@mui/material';
import React from 'react';

const SignIn: React.FC = () => {
  window.location.href = '/accounts/login/google-oauth2/';

  // TODO: Could use a much better design than just a line of text...
  return <Typography>Redirecting...</Typography>;
};

export default SignIn;
