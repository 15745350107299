import React from 'react';
import './App.css';
import { BatchDetailResponse, JobDetailResponse, loaderGet, post } from './api';
import {
  Link as RouterLink,
  Navigate,
  useMatches,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useRouteLoaderData,
  Outlet
} from 'react-router-dom';

import { CssBaseline, Link } from '@mui/material';
import StudentChoices from './student_choices';
import AddStudentChoices from './student_choices/AddStudentChoices';
import Timetable from './timetable';
import Import from './bulkChoices';
import PermissionedRoute from './routes/PermissionedRoute';
import Allocation from './allocation';
import BatchById from './allocation/BatchById';
import JobById from './allocation/JobById';
import { PERMISSION_BY_PATH } from './permission';
import Page404 from './routes/404';
import PageError from './routes/Error';
import PageMyPracticals, { MyPracticalsRedirect } from './my-practicals';
import RootLayout from './layouts/RootLayout';
import SignIn from './routes/SignIn';
import { ThemeProvider } from '@mui/material/styles';
import theme from './layouts/theme/MuiTheme';

type MatchHandle = {
  crumb: (data: any) => React.ReactNode;
};

export type Match = ReturnType<typeof useMatches>[0] & {
  handle: MatchHandle | undefined;
};

export interface User {
  firstName: string;
  lastName: string;
  crsid: string | null;
  permissions: string[];
}

const createApiLoader =
  (resource: RequestInfo | URL, method: 'GET' | 'POST' = 'GET') =>
  async ({ request }: { request: Request }) => {
    if (method === 'GET') return loaderGet(resource, { signal: request.signal });
    if (method === 'POST') return post(resource, { signal: request.signal });
    throw new Error(`Invalid method "${method}" on api loader request to "${resource}".`);
  };

const HomeRedirect: React.FC = () => {
  const user = useRouteLoaderData('authenticated') as User;
  const userPermissions = new Set(user?.permissions);

  return (
    <Navigate
      to={
        PERMISSION_BY_PATH.addStudentChoices.every((permission) => userPermissions.has(permission))
          ? '/student-choices'
          : '/my-practicals'
      }
      replace
    />
  );
};

const App: React.FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route id="root" errorElement={<PageError />} path="" element={<RootLayout />}>
        <Route path="" id="authenticated" loader={createApiLoader('/api/v1alpha1/users/current/')}>
          <Route path="" id="redirect" element={<HomeRedirect />} />
          <Route
            path="logout"
            id="logout"
            // App structure triggers multiple loader requests
            // And subsequent calls to logout will 403
            // Make sure to redirect to sign-in on error
            errorElement={<Navigate to="/sign-in" />}
            loader={createApiLoader('/api/v1alpha1/users/logout/', 'POST')}
            element={<Navigate to="/sign-in" />}
          />
          <Route
            path="student-choices"
            id="studentChoicesRoot"
            handle={{
              crumb: () => (
                <Link component={RouterLink} to="/student-choices">
                  Student Choices
                </Link>
              )
            }}
          >
            <Route
              path=""
              id="studentChoices"
              element={
                <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.studentChoices}>
                  <StudentChoices />
                </PermissionedRoute>
              }
            />
            <Route
              path="add"
              id="studentChoicesAdd"
              element={
                <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.addStudentChoices}>
                  <AddStudentChoices />
                </PermissionedRoute>
              }
              handle={{
                crumb: () => (
                  <Link component={RouterLink} to="/student-choices/add">
                    Add
                  </Link>
                )
              }}
            />
          </Route>
          <Route path="my-practicals" id="myLabsRoot">
            <Route path="" id="myPracticalsRedirect" element={<MyPracticalsRedirect />} />
            <Route
              path=":crsid"
              id="myPracticals"
              element={<PageMyPracticals />}
              loader={({ params, ...rest }) =>
                createApiLoader(
                  `/api/v1alpha1/jobs/published/students/${params.crsid}/teaching-units/`
                )(rest)
              }
            />
          </Route>
          <Route
            path="allocation"
            id="allocationRoot"
            element={
              <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.allocation}>
                <Outlet />
              </PermissionedRoute>
            }
            handle={{
              crumb: () => (
                <Link component={RouterLink} to="/allocation">
                  Allocation Batches
                </Link>
              )
            }}
          >
            <Route path="" id="allocation" element={<Allocation />} />
            <Route
              path=":batchId"
              id="batchRoot"
              loader={({ params, ...rest }) =>
                createApiLoader(`/api/v1alpha1/batches/${params.batchId}/`)(rest)
              }
              handle={{
                crumb: (data: BatchDetailResponse) => (
                  <Link component={RouterLink} to={`/allocation/${data.id}`}>
                    {data.name}
                  </Link>
                )
              }}
            >
              <Route path="" id="batch" element={<BatchById />} />
              <Route
                path="jobs/:jobId"
                id="job"
                element={<JobById />}
                loader={({ params, ...rest }) =>
                  createApiLoader(`/api/v1alpha1/jobs/${params.jobId}/`)(rest)
                }
                handle={{
                  crumb: (data: JobDetailResponse) => (
                    <Link
                      component={RouterLink}
                      to={`/allocation/${data.batchId}/jobs/${data.id}`}
                    >
                      Job {data.id}
                    </Link>
                  )
                }}
              />
            </Route>
          </Route>
          <Route
            path="bulk-choices"
            id="bulkChoices"
            element={
              <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.bulkChoices}>
                <Import />
              </PermissionedRoute>
            }
          />
          <Route
            path="timetable"
            id="timetable"
            element={
              <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.timetable}>
                <Timetable />
              </PermissionedRoute>
            }
          />
        </Route>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate replace to="404" />} />
      </Route>
    )
  );

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* FIXME: add global spinner */}
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
};

export default App;
