import React, { useEffect, useState } from 'react';
import StudentSelection from './StudentSelection';
import SubjectSelection from './SubjectSelection';
import { Box, Stack } from '@mui/material';
import { selectedSubjectsStore, studentSelectionStore } from './store';
import { post } from '../api';
import AllocatorUIModal, { modalStore, ModalTypes } from '../AllocatorUIModal';
import { LoadingButton } from '@mui/lab';

export const AddStudentChoices = () => {
  // form state
  const { selectedSubjects, clearSelectedSubjects } = selectedSubjectsStore();
  const { student, setCrsid, setStudent } = studentSelectionStore();
  const { setModal } = modalStore();
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [submittingStudentChoices, setSubmittingStudentChoices] = useState(false);

  useEffect(() => {
    if (selectedSubjects.length > 0 && student) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedSubjects, student]);

  const clearForm = () => {
    clearSelectedSubjects();
    setCrsid('');
    setStudent(null);
  };

  const handleSubmit = async () => {
    setSubmittingStudentChoices(true);
    try {
      await post('/api/v1alpha1/studentchoices/', {
        body: JSON.stringify({ subjects: selectedSubjects, crsid: student!.crsid })
      });
      clearForm();
      setModal('Your submission was successful', ModalTypes.MESSAGE);
    } catch (error) {
      setModal(
        `Submission failed: ${(error as Error).message}. Please check your inputs and try again.`,
        ModalTypes.ALERT
      );
    } finally {
      setSubmittingStudentChoices(false);
    }
  };

  return (
    <Stack className="add-student-choices" direction="column" spacing={2}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h2>Add Student Choices</h2>
      </Stack>
      <StudentSelection />
      <SubjectSelection />
      <Box>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={submittingStudentChoices}
          disabled={isSubmitDisabled}
        >
          Submit
        </LoadingButton>
      </Box>
      <AllocatorUIModal />
    </Stack>
  );
};

export default AddStudentChoices;
