import React from 'react';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { BatchDetailResponse } from '../api';

type JobsTableProps = {
  batchId: number;
  jobs: BatchDetailResponse['jobs'];
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Job ID',
    type: 'number',
    width: 100,
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => `#${params.value}`
  },
  {
    field: 'allocatedStudentCount',
    headerName: 'Allocated',
    width: 110,
    type: 'number'
  },
  {
    field: 'studentWithIncompatibleChoicesCount',
    headerName: 'Incompatible Choices',
    width: 140,
    type: 'number'
  },
  {
    field: 'overAllocatedPracticalCount',
    headerName: 'Over Capacity Practicals',
    width: 110,
    type: 'number'
  },
  {
    field: 'overAllocationSummed',
    headerName: 'Over Capacity Allocations',
    width: 120,
    type: 'number'
  },
  {
    field: 'permittedClashCount',
    headerName: 'Clashes',
    width: 100,
    type: 'number'
  },
  {
    field: 'chemistryPhysicsWeeklyAlternationCount',
    headerName: 'Chem/Phys Weekly Alternation',
    width: 130,
    type: 'number'
  },
  {
    field: 'chemistryPhysicsPerfectAlternationCount',
    headerName: 'Chem/Phys Perfect Alternation',
    width: 130,
    type: 'number'
  }
];

const JobsTable: React.FC<JobsTableProps> = ({ batchId, jobs }) => {
  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/allocation/${batchId}/jobs/${params.id}`);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            textAlign: 'right'
          }
        }}
        columnHeaderHeight={80}
        rows={jobs}
        columns={columns}
        onRowClick={handleRowClick}
        getRowClassName={() => 'clickable'}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
      />
    </div>
  );
};

export default JobsTable;
