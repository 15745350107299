import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BatchResponse } from '../api';
import { useNavigate } from 'react-router-dom';

type BatchesTableProps = {
  batches: BatchResponse[];
};

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'studentChoicesCollectionName', headerName: 'Student Choice Collection', width: 280 },
  { field: 'timetableName', headerName: 'Timetable', width: 180 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    renderCell: (c) => (c.value ? new Date(c.value).toLocaleString() : null)
  }
];

const BatchesTable: React.FC<BatchesTableProps> = ({ batches }) => {
  const navigate = useNavigate();

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={batches}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }]
          }
        }}
        onRowClick={(e) => navigate(`${e.id}`)}
        getRowClassName={() => 'clickable'}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default BatchesTable;
