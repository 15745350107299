import React from 'react';
import { Breadcrumbs, Container } from '@mui/material';
import Nav from '../Nav';
import { Outlet, useMatches, useRouteLoaderData } from 'react-router-dom';
import { Match, User } from '../App';
import { PERMISSION_BY_PATH } from '../permission';
import { useMemo } from 'react';
import Header from '../Header';

const RootLayout = ({ children }: { children?: React.ReactNode }) => {
  // Root layout is used for both authenticated and unauthenticated pages
  // So handle this loader not being defined
  const user = useRouteLoaderData('authenticated') as User | undefined;
  const userPermissions = useMemo(() => new Set(user ? user.permissions : []), [user]);
  // Show the navigation if the user has any of the permissions required for the admin pages
  const showNavigation = useMemo(
    () =>
      Object.values(PERMISSION_BY_PATH).some((permissions) =>
        permissions.every((permission) => userPermissions.has(permission))
      ),
    [userPermissions]
  );

  let matches = useMatches() as Match[];
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle?.crumb(match.data));

  return (
    <>
      <Header user={user} />
      {showNavigation && <Nav />}
      <Container sx={{ py: 4 }}>
        {crumbs.length > 0 && (
          <Breadcrumbs aria-label="breadcrumb">
            {crumbs.map((crumb, index) => (
              <div key={index}>{crumb}</div>
            ))}
          </Breadcrumbs>
        )}
        {children ? children : <Outlet />}
      </Container>
    </>
  );
};

export default RootLayout;
