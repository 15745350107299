import { create } from 'zustand';
import { Student } from '../api';

interface SelectedSubjectsState {
  selectedSubjects: string[];
  clearSelectedSubjects(): void;
}

export const selectedSubjectsStore = create<SelectedSubjectsState>((set) => ({
  selectedSubjects: [],
  clearSelectedSubjects: () => set({ selectedSubjects: [] })
}));

interface StudentSelectionState {
  crsid: string;
  student: Student | null;
  setCrsid: (crsid: string) => void;
  setStudent: (student: Student | null) => void;
}

export const studentSelectionStore = create<StudentSelectionState>((set) => ({
  crsid: '',
  student: null,
  setCrsid: (crsid) => set({ crsid }),
  setStudent: (student) => set({ student })
}));
