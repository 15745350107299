import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';

type Props = {
  batchCreatedAt: string;
  timetableName: string;
  choiceCollectionName: string;
  open: boolean;
  handleClose: () => void;
  handleConfirmation: () => void;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 2
};

const buttonsStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  mt: 2
};

const PublishConfirmationModal: React.FC<Props> = ({
  open,
  handleConfirmation,
  handleClose,
  batchCreatedAt,
  timetableName,
  choiceCollectionName
}) => {
  const batchCreatedAtAsDate = new Date(Date.parse(batchCreatedAt));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <div id="modal-description">
            <Typography sx={{ mt: 2, mb: 1 }}>
              This will override the existing publication.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              This job was initiated at <b>{batchCreatedAtAsDate.toString()}</b> using timetable
              named <b>{timetableName}</b> and student choice collection named{' '}
              <b>{choiceCollectionName}</b> at the time of initiation.
            </Typography>
            <Typography>Are you sure you want to publish?</Typography>
          </div>
          <Box sx={buttonsStyle}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmation}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PublishConfirmationModal;
