import { Box, Paper, Stack, styled } from '@mui/material';
import { AllocatedTeachingUnit } from '../api';
import Practical from './Practical';
import EmptyPractical from './EmptyPractical';

const StyledPaper = styled(Paper)(() => ({
  border: '1px solid #D0D5DC',
  '.practical-container:not(:last-child)': {
    borderBottom: '1px solid #D0D5DC'
  }
}));

const Practicals: React.FC<{ data: AllocatedTeachingUnit[] }> = ({ data }) => {
  if (data.length === 0) {
    return <EmptyPractical />;
  }

  return (
    <StyledPaper className="practicals" elevation={0}>
      <Stack>
        {data.map((practical) => (
          <Box key={practical.code} className="practical-container" sx={{ p: 1 }}>
            <Practical {...practical} />
          </Box>
        ))}
      </Stack>
    </StyledPaper>
  );
};

export default Practicals;
