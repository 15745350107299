import React, { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import WarningIcon from '@mui/icons-material/Warning';

import { AllocatedTeachingUnit } from '../api';

const DAY_MAPPING = new Map([
  ['MON', 'Monday'],
  ['TUE', 'Tuesday'],
  ['WED', 'Wednesday'],
  ['THU', 'Thursday'],
  ['FRI', 'Friday'],
  ['SAT', 'Saturday'],
  ['SUN', 'Sunday']
]);

type PracticalTimeSlot = Omit<AllocatedTeachingUnit['timeSlots'][0], 'week'> & {
  week: 'EVEN' | 'ODD' | 'EVERY';
};

const convert24HourTo12Hour = (time_24_fmt: string) => {
  // Check correct time format and split into components
  let time: string[] = time_24_fmt.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time_24_fmt
  ];

  if (time.length > 1) {
    // time in expected format
    time = time.slice(1); // remove full string match value
    time[5] = +time[0] < 12 ? 'am' : 'pm';
    // adjust hours
    const hour = +time[0] % 12 || 12;
    time[0] = hour.toString();
  }
  return time.join(''); // return adjusted time or original string
};

const PracticalLine: React.FC<PropsWithChildren<{ icon: React.ReactElement }>> = ({
  icon,
  children
}) => (
  <Stack alignItems="center" direction="row" gap={2}>
    {icon}
    <Typography variant="body1" sx={{ fontSize: { xs: '20px', sm: '16px' }, fontWeight: 400 }}>
      {children}
    </Typography>
  </Stack>
);

const ClashWarning: React.FC = () => {
  return (
    <Stack direction="row" gap={2} sx={{ alignItems: { xs: 'flex-start', md: 'center' } }}>
      <WarningIcon color="warning" sx={{ marginTop: { xs: '4px', md: 0 } }} />
      <Typography variant="body1">
        This practical allocation has a known clash with a timetabled lecture. You should
        prioritise your lecture.
      </Typography>
    </Stack>
  );
};

export const Practical: React.FC<AllocatedTeachingUnit> = ({ timeSlots, hasPermittedClash }) => {
  const timeSlot: PracticalTimeSlot = useMemo(() => {
    if (timeSlots.length === 0) {
      console.error('allocated practical missing time slots');
      throw Error('allocated practical missing time slots');
    } else if (timeSlots.length === 1) {
      // either ODD or EVEN week
      return timeSlots[0];
    } else if (timeSlots.length === 2) {
      if (
        timeSlots[0].day !== timeSlots[1].day ||
        timeSlots[0].start !== timeSlots[1].start ||
        timeSlots[0].finish !== timeSlots[1].finish
      ) {
        console.error('practical time slots should match day start and finish');
        throw Error('practical time slots should match day, start and finish');
      }
      return {
        ...timeSlots[0],
        week: 'EVERY'
      };
    } else {
      console.error('unexpected number of time slots for a practical');
      throw Error('unexpected number of time slots for a practical');
    }
  }, [timeSlots]);

  const everyWeek = useMemo(() => {
    return timeSlot.week === 'EVERY' ? true : false;
  }, [timeSlot]);

  return (
    <Grid className="practical" container spacing={1} sx={{ p: 1, width: 'auto' }}>
      <Grid item xs={12} sm={4} md={3}>
        <PracticalLine icon={<EventIcon />}>
          {`${
            timeSlot.week.charAt(0).toUpperCase() + timeSlot.week.substring(1).toLocaleLowerCase()
          } ${DAY_MAPPING.get(timeSlot.day)}${!everyWeek ? 's' : ''}`}
        </PracticalLine>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <PracticalLine icon={<AccessTimeIcon />}>
          {`${convert24HourTo12Hour(timeSlot.start)} to ${convert24HourTo12Hour(timeSlot.finish)}`}
        </PracticalLine>
      </Grid>
      {hasPermittedClash && (
        <Grid item xs={12}>
          <ClashWarning />
        </Grid>
      )}
    </Grid>
  );
};

export default Practical;
